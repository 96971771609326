import React from 'react'

type Props ={
  productDetail?:string
}

function ProductDetail(props:Props) {
  return (
    <>
     <div id="des-details1" className="tab-pane">
                                <div className="product-description-wrapper">
                                    <p>{props.productDetail}
                                    </p>
                                    
                                </div>
                            </div>
    </>
  )
}

export default ProductDetail
