import React, { useState,useEffect } from 'react'
import TeamCard from '../components/teamcard'
import BreadCrumb from '../components/breadcrumb'
import infoService from '../services/info-service';
import infoImageService from '../services/info-image-service';
import teamService from '../services/team-service';


function About() {
    const [info, setInfo] = useState<any>();
    const [infoImage, setInfoImage] = useState<any>();
    const [team, setTeam] = useState<any>();

    const getInfo = async ()=>{
        await infoService.getAll()
            .then((r)=>{
                        setInfo(r.data?.items[0]);
                         console.log(info);
                         
                        }
        );
    }
    const getTeams= async ()=>{
        await teamService.getAll()
            .then((r)=>{
                        setTeam(r.data?.items);
                         console.log(info);
                         
                        }
        );
    }
    const getInfoImages = async ()=>{
        await infoImageService.getAll()
            .then((r)=>{
                        setInfoImage(r.data);
                         
                        }
        );
    }
    useEffect(() => {
		getInfo();
        getInfoImages();
        getTeams();
	  }, []);

    return (
        <>
        <BreadCrumb title={'Hakkımızda'} firstItem={'Ana Sayfa'} secondItem={'Hakkımızda'}/>
         
            <section className="about-area pb-100px pt-100px" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left-image mb-md-30px mb-lm-30px" data-aos="fade-up">
                                <img src={info?.image} alt="" className="img-responsive w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="about-title" data-aos="fade-up">
                                    <h2>{info?.title}</h2>
                                </div>
                                <p className="mb-30px" data-aos="fade-up" data-aos-delay="200">
                                {info?.firstText}
                                <b>(Özelleştirilebilir)</b>
                                </p>
                                <p data-aos="fade-up" data-aos-delay="300">
                                    
                                    {info?.secondText} 
                                    <b>(Özelleştirilebilir)</b>
                                </p>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div className="container" style={{ marginTop: '5px' }}>

                </div>
            </section>
            <div className="banner-preduct-wrapper pt-100px pb-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 pr-0">
                            <div className="banner-product-image">
                                <a className="venobox " href="assets/images/banner/8.jpg" data-gall="myGallery">
                                    <img src={infoImage?.items[0]?.imageUrl} className="img-fluid w-100" alt="Banner images" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="banner-product-image mt-lm-15px">
                                <a className="venobox " href="assets/images/banner/9.jpg" data-gall="myGallery">
                                    <img src={infoImage?.items[1]?.imageUrl} className="img-fluid w-100" alt="Banner images" />
                                </a>
                            </div>
                            <div className="banner-product-image mt-3">
                                <a className="venobox " href="assets/images/banner/10.jpg" data-gall="myGallery">
                                    <img src={infoImage?.items[2]?.imageUrl} className="img-fluid w-100" alt="Banner images" />
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="team-section">
                <div className="section-title-wrapper" data-aos="fade-up" data-aos-delay="0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Takımımız</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-wrapper">
                    <div className="container">
                        <div className="row">
                            {
                                team?.map((r:any)=>(
                                    <TeamCard name={r.firstName + r.lastName} title={r.role} imageUrl={r?.imageUrl} instagramLink={r.instagramLink} />
                                ))

                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="company-logo-section pt-100px pb-100px">
                <div className="company-logo-wrapper" data-aos="fade-up" data-aos-delay="0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="company-logo-slider slider-nav-style-1">
                                    <div className="swiper-container company-logo-slider">
                                        <div className="swiper-wrapper">

                                            <div className="company-logo-single-item swiper-slide">
                                                <div className="image"><img className="img-fluid"
                                                    src={info?.logoUrl} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
