import React from 'react'
import Reviewform from '../reviewform'

type Props = {
    productReviews?: any;
}
function ProductReviews(props: Props) {
    return (
        <>
            <div id="des-details3" className="tab-pane">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="review-wrapper">
                            {
                                props.productReviews?.reverse().map((r: any) => (
                                    <div className="single-review">

                                        <div className="review-content">
                                            <div className="review-top-wrap">
                                                <div className="review-left">
                                                    <div className="review-name">
                                                        <h4>{r.name}</h4>
                                                    </div>
                                                    <div className="rating-product">
                                                        {Array.from({ length: 5 }, (_, i) => (
                                                            <i
                                                                key={i}
                                                                className={i < r.point ? "ion-android-star" : "ion-android-star-outline"}
                                                            ></i>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="review-bottom">
                                                <p>
                                                    {r.message}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="col-lg-5">
                        <Reviewform />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductReviews
