import React from "react";
import {Route, Routes} from "react-router-dom";
import ErrorPage from "../../pages/404";
import Mpage from "../../pages/Mpage";
import About from "../../pages/About";
import Account from "../../pages/Account";
import Cart from "../../pages/Cart";
import Contact from "../../pages/Contact";
import LoginPage from "../../pages/LoginPage";
import SingleProduct from "../../pages/SingleProduct";
import Shop3column from "../../pages/shop-3column";
import Admin from "../../pages/Admin";
// import Homepage from "../../pages/Homepage/Homepage";
// import NotFound from "../../pages/NotFound/NotFound";
// import About from "../../pages/About/About";

type Props = {
	company:any;
};

const RouteDefinitions = (props: Props) => {
	return (
		<Routes>
			{/* <Route path="/" element={<Homepage />} />
			<Route path="/about" element={<About />} />
			<Route path="*" element={<NotFound />} /> */}
			<Route path="/" element={<Mpage mainImage={props.company?.mainImage} />} />
			<Route path="/about" element={<About />} />
			<Route path="/account" element={<Account />} />
			<Route path="/cart" element={<Cart />} />
			<Route path="/contact" element={<Contact company={props.company}/>} />
			<Route path="/auth" element={<LoginPage />} />
			<Route path="/single-product/:productId" element={<SingleProduct />} />
			<Route path="/search/single-product/:productId" element={<SingleProduct />} />
			<Route path="/search" element={<Shop3column />} />
			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
};

export default RouteDefinitions;