import {BaseService} from "../core/services/base-service";

class CategoryService extends BaseService<
	any,
	any,
any,
	any,
	any,
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "Categories";
	}

}

export default new CategoryService();