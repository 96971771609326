import React, { useEffect, useState } from 'react'
import BreadCrumb from '../components/breadcrumb'
import ProductCard from '../components/ProductCard'
import productService from '../services/product-service';
import categoryService from '../services/category-service';
import colorService from '../services/color-service';
import sizeService from '../services/size-service';
import { Link } from 'react-router-dom';

function Shop3column() {

    const [products, setProducts] = useState<any>();
    const [categories, setCategories] = useState<any>();
    const [sizes, setSizes] = useState<any>();
    const [colors, setColors] = useState<any>();
    const [minPrice, setMinPrice] = useState<any>();
    const [maxPrice, setMaxPrice] = useState<any>();
    const [clickedCategory, setClickedCategory] = useState<any>(null);
    const [clickedSize, setClickedSize] = useState<any>(null);
    const [clickedColor, setClickedColor] = useState<any>(null);
    const [clickedSubCategory, setClickedSubCategory] = useState<any>(null);


    const getProducts = async () => {
        await productService.getList(0, 500).then((r) => {
            setProducts(r.data.items);
            console.log(r.data.items);

        })
    }
    const getCategories = async () => {
        await categoryService.getList(0, 200).then((r) => {
            setCategories(r.data.items);

        })
    }
    const getColors = async () => {
        await colorService.getList(0, 200).then((r) => {
            setColors(r.data.items);
        })
    }
    const getSizes = async () => {
        await sizeService.getList(0, 200).then((r) => {
            setSizes(r.data.items);
        })
    }
    const handleCategoryClick = (categoryId: any) => {
        setClickedCategory(categoryId);
        setClickedSize(null); // Diğer filtreleri sıfırlama
        setClickedColor(null);
        setClickedSubCategory(null);
    };

    // Yalnızca bir boyutun seçilmesini sağlama
    const handleSizeClick = (sizeId: any) => {
        setClickedSize(sizeId);
        setClickedCategory(null); // Diğer filtreleri sıfırlama
        setClickedColor(null);
        setClickedSubCategory(null);
    };

    // Yalnızca bir rengin seçilmesini sağlama
    const handleColorClick = (colorId: any) => {
        setClickedColor(colorId);
        setClickedCategory(null); // Diğer filtreleri sıfırlama
        setClickedSize(null);
        setClickedSubCategory(null);
    };

    // Yalnızca bir alt kategorinin seçilmesini sağlama
    const handleSubCategoryClick = (subCategoryId: any) => {
        setClickedSubCategory(subCategoryId);
        setClickedCategory(null); // Diğer filtreleri sıfırlama
        setClickedSize(null);
        setClickedColor(null);
    };

    // Filtreleme fonksiyonu
    const filteredProducts = products?.filter((product: any) => {
        if (clickedCategory) {
            return product.productCategory.some((category: any) => category.categoryId === clickedCategory);
        }
        if (clickedSize) {
            return product.productSize.some((size: any) => size.sizeId === clickedSize);
        }
        if (clickedColor) {
            return product.productColor.some((color: any) => color.colorId === clickedColor);
        }
        if (clickedSubCategory) {
            return product.productSubCategory.some((subCategory: any) => subCategory.subCategoryId === clickedSubCategory);
        }

        if (minPrice !== null && product.price < minPrice) {
            return false;
        }
        if (maxPrice !== null && product.price > maxPrice) {
            return false;
        }

        return true;
    });
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([getProducts(), getCategories(), getColors(), getSizes()]);
        };
        fetchData();
    }, []);
    



    return (
        <>
            <BreadCrumb title={'Alışveriş'} firstItem={'Ana Sayfa'} secondItem={'Arama'} />

            <div className="shop-category-area pb-100px pt-70px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 order-lg-last col-md-12 order-md-first">


                            <div className="shop-bottom-area">

                                <div className="row">

                                {filteredProducts && filteredProducts.map((r: any) => (
    <div key={r.id || Math.random()} className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
        (r &&<ProductCard product={r} />)
    
  </div>
))}



                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
                            <div className="shop-sidebar-wrap">

                                <div className="sidebar-widget">
                                    <div className="main-heading">
                                        <h3 className="sidebar-title">Kategoriler</h3>
                                    </div>
                                    <div className="sidebar-widget-category">
                                        <ul>
                                            <li>
                                                <a
                                                    href="#"
                                                    style={{ cursor: 'pointer' }}
                                                    className="selected"
                                                    onClick={() => {
                                                        setClickedCategory(null);
                                                        setClickedSize(null);
                                                        setClickedColor(null);
                                                        setClickedSubCategory(null);
                                                    }}
                                                >
                                                    <b>Hepsi</b> ({products?.length})
                                                </a>
                                            </li>


                                            {
                                                (categories?.map((cat: any) => (
                                                    <>
                                                        <hr />
                                                        <li key={cat.id}>
                                                            <a  style={{ cursor: 'pointer' }} onClick={() => handleCategoryClick(cat.id)}>

                                                                <b>{cat.name}
                                                                </b>
                                                                <span>
                                                                    ({products?.filter((product: any) =>
                                                                        product.productCategory.some((category: any) => category.categoryId === cat.id)
                                                                    ).length})
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <hr />
                                                        {cat.subCategory?.map((sc: any) => (
                                                            <>

                                                                <li key={sc.id}><a  style={{ cursor: 'pointer' }} onClick={() => handleSubCategoryClick(sc.id)}>
                                                                    {sc.name}  <span>
                                                                        (
                                                                        {products?.filter(
                                                                            (product: any) =>

                                                                                product.productSubCategory.some((category: any) => category.subCategoryId === sc.id)
                                                                        ).length
                                                                        })

                                                                    </span> </a></li></>
                                                        ))}
                                                    </>
                                                )
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </div>

                                <div className="sidebar-widget-group">
                                    <h3 className="sidebar-title">Filtrele</h3>
                                    <div className="sidebar-widget">
                                        <h4 className="pro-sidebar-title">Ucret</h4>
                                        <div className="price-filter">
                                            <div className="price-slider-amount">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <input
                                                            type="number"
                                                            onChange={(e) => {
                                                                let value = parseFloat(e.target.value);
                                                                if (value < 0) {
                                                                    value = 0;
                                                                }
                                                                setMinPrice(value);
                                                            }}
                                                            id="amount"
                                                            className="p-0 h-auto lh-1"
                                                            name="price"
                                                            defaultValue={0}
                                                            value={minPrice}
                                                            placeholder="Minimum Ücret"
                                                        />                                                  </div>
                                                    <div className="col-lg-6">
                                                        <input
                                                            type="number"
                                                            onChange={(e) => {
                                                                setMaxPrice(e.target.value ? parseFloat(e.target.value) : 100000000000000000000000);
                                                            }}
                                                            id="amount"
                                                            className="p-0 h-auto lh-1"
                                                            name="price"
                                                            defaultValue={10000000}
                                                            value={maxPrice}
                                                            placeholder="Minimum Ücret"
                                                        />
                                                    </div>

                                                </div>


                                            </div>
                                            {/* <div id="slider-range"></div> */}
                                        </div>
                                    </div>

                                    <div className="sidebar-widget">
                                        <h4 className="pro-sidebar-title mt-5">Beden</h4>
                                        <div className="sidebar-widget-list">
                                            <ul>
                                                {
                                                    sizes?.map((r: any) => (
                                                        <li>
                                                            <div key={r.id} className="sidebar-widget-list-left">
                                                                <input type="checkbox" />
                                                                <a style={{ cursor: 'pointer' }} onClick={() => handleSizeClick(r.id)}>
                                                                    {r.name}

                                                                    <span>
                                                                        (
                                                                        {products?.filter(
                                                                            (product: any) =>

                                                                                product.productSize.some((size: any) => size.sizeId === r.id)
                                                                        ).length
                                                                        }
                                                                        )

                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    ))
                                                }


                                            </ul>
                                        </div>
                                    </div>

                                    <div className="sidebar-widget no-cba">
                                        <h4 className="pro-sidebar-title">Renk</h4>
                                        <div className="sidebar-widget-list">
                                            <ul>
                                                {
                                                    colors?.map((r: any) => (
                                                        <li>
                                                            <div key={r.id} className="sidebar-widget-list-left">
                                                                <input type="checkbox" />
                                                                <a style={{ cursor: 'pointer' }} onClick={() => handleColorClick(r.id)}>
                                                                    {r.name}

                                                                    <span>
                                                                        (
                                                                        {products?.filter(
                                                                            (product: any) =>

                                                                                product.productColor.some((size: any) => size.colorId === r.id)
                                                                        ).length
                                                                        }
                                                                        )

                                                                    </span>
                                                                </a>
                                                                <span className="checkmark grey" style={{ backgroundColor: r.code }}></span>
                                                            </div>
                                                        </li>

                                                    ))
                                                }


                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shop3column
