import React from 'react'

type Props = {
    title:string,
    firstItem:string,
    secondItem:string,
    thirdItem?:string
}

function BreadCrumb(props:Props) {
  return (
    <div>
       <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row breadcrumb_box  align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
                                    <h2 className="breadcrumb-title">{props.title}</h2>
                                </div>
                                <div className="col-lg-6  col-md-6 col-sm-12">
                                    <ul className="breadcrumb-list text-center text-md-end">
                                        <li className="breadcrumb-item"><a href="index.html">{props.firstItem}</a></li>
                                        <li className="breadcrumb-item active"><a href="shop-left-sidebar.html">{props.secondItem}</a></li>
                                        {props.thirdItem && (<li className="breadcrumb-item active">{props.thirdItem}</li>)} 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default BreadCrumb
