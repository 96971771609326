import React from 'react'
import BreadCrumb from '../components/breadcrumb'
import AccountUpdate from '../components/Account/AccountUpdate'

function Account() {
  return (
    <>
    <BreadCrumb title={'Mağaza'} firstItem={'Ana Sayfa'} secondItem={'Profil'}/>
    

    <div className="checkout-area pt-100px pb-100px">
        <div className="container">
            <div className="row">
                <div className="ml-auto mr-auto col-lg-9">
                    <div className="checkout-wrapper">
                        <div id="faq" className="panel-group">
                            <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay="200">
                                <div className="panel-heading my-account-title">
                                    <h3 className="panel-title"><span>1 .</span> <a data-bs-toggle="collapse" className="collapsed" aria-expanded="true" href="#my-account-1">Profil Detaylarını Düzenle </a>
                                    </h3>
                                </div>
                                <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
                                    <div className="panel-body">
                                       <AccountUpdate/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="panel panel-default single-my-account" data-aos="fade-up" data-aos-delay="400">
                                <div className="panel-heading my-account-title">
                                    <h3 className="panel-title"><span>2 .</span> <a data-bs-toggle="collapse" className="collapsed" aria-expanded="false" href="#my-account-2">Şifre İşlemleri </a></h3>
                                </div>
                                 <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
                                    <div className="panel-body">
                                        <div className="myaccount-info-wrapper">
                                            <div className="account-info-wrapper">
                                                <h4>Şifremi Değiştir</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="billing-info">
                                                        <label>Mevcut Şifreniz</label>
                                                        <input type="password" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="billing-info">
                                                        <label>Yeni Şifreniz</label>
                                                        <input type="password" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="billing-info">
                                                        <label>Yeni Şifreniz Tekrar</label>
                                                        <input type="password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="billing-back-btn">
                                                <div className="billing-back">
                                                </div>
                                                <div className="billing-btn">
                                                    <button type="submit">Kaydet</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Account
