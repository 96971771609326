import React, { useEffect,useState } from 'react'
import ProductCard from './ProductCard'
import bestsellerService from '../services/bestseller-service';

function ProductTab() {

    const [bestSellers,setBestSellers]=useState<any>();

    const getBestSellers = async ()=>{
        await bestsellerService.getList(0,4).then((r)=>{
            setBestSellers(r.data.items);
            console.log(r.data.items);
            
        })
    }

    useEffect(()=>{
        getBestSellers();
    },[])
  return (
    <div className="section product-tab-area">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center" data-aos="fade-up">
                    <div className="section-title mb-0">
                        <h2 className="title mt-10" style={{marginTop:'30px'}}>En Çok Satanlar</h2>
                    </div>
                </div>

            
          
        
            </div>
            <div className="row">
                <div className="col">
                    <div className="tab-content">
                    
                        <div className="  " id="tab-product-new-arrivals">
                            <div className="row">
                                {
                                    bestSellers?.map((r:any)=>(
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-30px" data-aos="fade-up" data-aos-delay="200">
                                             <ProductCard product={r.product}/>
                                             
                                         </div>
                                    ))
                                }
                               
                             
                            
                                
                               
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductTab
