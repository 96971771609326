import React, { useState } from 'react'
import formService from '../services/form-service';
import toastr from 'toastr';
function Formm() {
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        subject: '',
        message: ''
    });
        
    const handleChange = (e:any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    
        const handleSubmit = async (e:any) => {
            e.preventDefault();
           await formService.add(formData).then(()=>{
            toastr.success('Formunuz Başarıyla GÖnderildi')
           }).then(()=>{
            setFormData({
                firstName: '',
                lastName: '',
                subject: '',
                message: ''
            });
           }).catch((r)=>console.log(r)
           )
        }
    return (
        <>
               <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
            <div className="contact-form">
                <div className="contact-title mb-30">
                    <h2 className="title" data-aos="fade-up" data-aos-delay="200">Bize Mesaj Gönderin</h2>
                </div>
                <form className="contact-form-style" id="contact-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <input
                                name="firstName"
                                placeholder="İsim*"
                                type="text"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <input
                                name="lastName"
                                placeholder="Soyisim*"
                                type="text"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
                            <input
                                name="subject"
                                placeholder="Konu*"
                                type="text"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-lg-12" data-aos="fade-up" data-aos-delay="200">
                            <textarea
                                name="message"
                                placeholder="Mesaj*"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                            <button
                                className="btn btn-primary btn-hover-dark mt-4"
                                data-aos="fade-up"
                                data-aos-delay="200"
                                type="submit"
                           
                            >
                                Gönder
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}
export default Formm
