import React from 'react'

function Modal() {
  return (
    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">x</span></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                            <div className="swiper-container gallery-top mb-20px">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-container gallery-thumbs slider-nav-style-1 small-nav">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                    </div>
                                    <div className="swiper-slide">
                                        <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt=""/>
                                    </div>
                                </div>
                                <div className="swiper-buttons">
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12">
                            <div className="product-details-content quickview-content">
                                <h2>Originals Kaval Windbr</h2>
                                <p className="reference">Reference:<span> demo_17</span></p>
                                <div className="pro-details-rating-wrap">
                                    <div className="rating-product">
                                        <i className="ion-android-star"></i>
                                        <i className="ion-android-star"></i>
                                        <i className="ion-android-star"></i>
                                        <i className="ion-android-star"></i>
                                        <i className="ion-android-star"></i>
                                    </div>
                                    <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span>
                                </div>
                                <div className="pricing-meta">
                                    <ul>
                                        <li className="old-price not-cut">$18.90</li>
                                    </ul>
                                </div>
                                <p className="quickview-para">Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim venialo quis nostrud exercitation ullamco</p>
                                <div className="pro-details-size-color">
                                    <div className="pro-details-color-wrap">
                                        <span>Color</span>
                                        <div className="pro-details-color-content">
                                            <ul>
                                                <li className="blue"></li>
                                                <li className="maroon active"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="pro-details-quality">
                                    <div className="cart-plus-minus">
                                        <input className="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                                    </div>
                                    <div className="pro-details-cart btn-hover">
                                        <button className="add-cart btn btn-primary btn-hover-primary ml-4"> Add To
                                            Cart</button>
                                    </div>
                                </div>
                                <div className="pro-details-wish-com">
                                    <div className="pro-details-wishlist">
                                        <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                            wishlist</a>
                                    </div>
                                    <div className="pro-details-compare">
                                        <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                                    </div>
                                </div>
                                <div className="pro-details-social-info">
                                    <span>Share</span>
                                    <div className="social-info">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="ion-social-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-google"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-instagram"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal
