import React from 'react'
import BreadCrumb from '../components/breadcrumb'
import Login from '../components/Auth/Login'
import Register from '../components/Auth/Register'

function LoginPage() {
  return (
    <>
    <BreadCrumb title={'Mağaza'} firstItem={'Ana Sayfa'} secondItem={'Giriş'}/>
      

    <div className="login-register-area pt-100px pb-100px">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <div className="login-register-wrapper">
                        <div className="login-register-tab-list nav">
                            <a className="active" data-bs-toggle="tab" href="#lg1">
                                <h4>Giriş</h4>
                            </a>
                            <a data-bs-toggle="tab" href="#lg2">
                                <h4>Üye Ol</h4>
                            </a>
                        </div>
                        <div className="tab-content">
                            <div id="lg1" className="tab-pane active">
                                <div className="login-form-container">
                                    <div className="login-register-form">
                                        <Login/>
                                    </div>
                                </div>
                            </div>
                            <div id="lg2" className="tab-pane">
                                <div className="login-form-container">
                                    <div className="login-register-form">
                                       <Register/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
   
  )
}

export default LoginPage
