import React from 'react'
import BannerSection from '../components/bannersection'
import HeroIntroSlider from '../components/herointroslider'
import ProductCategory from '../components/productCategory'
import ProductTab from '../components/productTab'
import MainMenu from '../components/mainmenu/mainmenu'
type Props ={
  mainImage:string;
}


function Mpage(props:Props) {
  return (
    <>
    
     <HeroIntroSlider mainImage={props.mainImage}/>
      {/* <ProductCategory/> */}
      <ProductTab/>
    </>
  )
}

export default Mpage
