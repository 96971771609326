
import {BaseService} from "../core/services/base-service";

class CompanyService extends BaseService<
	any,
	any,
	any,
	any,
	any,
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "Companies";
	}

	getByFilter() {}

	setCompany():any{
		this.getAll().then((r)=>{
			localStorage.setItem("companyState", JSON.stringify(r.data.items[0]));
		  });
	}
	getCompany():any{
		const savedState = localStorage.getItem("companyState");
		if (savedState) {
		  return JSON.parse(savedState);
		}
		return null; 
	}
}

export default new CompanyService();