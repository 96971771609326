import React from 'react'
import BreadCrumb from '../components/breadcrumb'
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Formm from '../components/form';


type Props = {
    company: any;
};

function Contact(props: Props) {
    return (
        <>
            <BreadCrumb title={'İletişim'} firstItem={'Ana Sayfa'} secondItem={'İletişim'} />
            <div className="contact-area pb-100px pt-100px">
                <div className="container">
                    <div className="contact-map mb-50px">
                        <div id="mapid" data-aos="fade-up" data-aos-delay="200">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe id="gmap_canvas" src={props.company?.googleAddress} frameBorder="0" scrolling="no"></iframe>
                                    <a href="https://sites.google.com/view/maps-api-v2/mapv2"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-row-2 row">
                        <div className="col-lg-4 col-md-5 mb-lm-60px col-sm-12 col-xs-12 w-sm-100">
                            <div className="contact-info-wrap">
                                <h2 className="title" data-aos="fade-up" data-aos-delay="200">İletişim</h2>
                                <div className="single-contact-info" data-aos="fade-up" data-aos-delay="200">
                                    <div className="contact-info-inner">
                                        <span className="sub-title">Telefon:</span>
                                    </div>
                                    <div className="contact-info-dec">
                                        <p><a href={props.company?.phoneNumber}>{props.company?.phoneNumber}</a></p>
                                    </div>
                                </div>
                                <div className="single-contact-info" data-aos="fade-up" data-aos-delay="200">
                                    <div className="contact-info-inner">
                                        <span className="sub-title">Mail:</span>
                                    </div>
                                    <div className="contact-info-dec">
                                        <p><a href={'mailto://' +props.company?.mailAddress}>{props.company?.mailAddress}</a></p>
                                    </div>
                                </div>
                                <div className="single-contact-info" data-aos="fade-up" data-aos-delay="200">
                                    <div className="contact-info-inner">
                                        <span className="sub-title">Adres:</span>
                                    </div>
                                    <div className="contact-info-dec">
                                        {(() => {
                                            const address = props.company?.address || '';
                                            const words = address.split(' ');

                                            const firstLine = words.slice(0, 2).join(' ');
                                            const secondLine = words.slice(2).join(' ');

                                            return (
                                                <>
                                                    <p>{firstLine}</p>
                                                    <p>{secondLine}</p>
                                                </>
                                            );
                                        })()}
                                    </div>
                                </div>
                                <div className="contact-social">
                                    <h3 className="title" data-aos="fade-up" data-aos-delay="200">Takip Edin</h3>
                                    <div className="social-info" data-aos="fade-up" data-aos-delay="200">
                                        <ul className="d-flex">

                                            <li className="li">
                                                <a title="Whatsapp" href={'https://wa.me/'+props.company?.phoneNumber} target="_blank" >
                                                    <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                                    
                                                </a>
                                             
                                            </li>
                                            <li className="li">
                                                <a title="Instagram" href={props.company?.instagramLink} target='_blank' style={{ marginLeft: '10px' }}> <FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formm/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
