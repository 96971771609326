import React from 'react'

type Props ={
    name:string;
    title:string;
    imageUrl:string;
    instagramLink:string;
}

function TeamCard(props:Props) {
  return (
    <>
    <div className="col-lg-3 col-md-6 mb-25px">
                        <div className="team-single" data-aos="fade-up" data-aos-delay="0">
                            <div className="team-img">
                                <img className="img-fluid" src={props.imageUrl}alt=""/>
                            </div>
                            <div className="team-content">
                                <h6 className="team-name font--bold mt-5">{props.name}</h6>
                                <span className="team-title">{props.title}</span>
                                <ul className="team-social pos-absolute">
                                    <li><a href={props.instagramLink}><i className="ion-social-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default TeamCard
