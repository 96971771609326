
import {BaseService} from "../core/services/base-service";
import { GetAllSampleResponse, GetSampleDetailResponse, AddSampleRequest, AddSampleResponse, UpdateSampleRequest, UpdateSampleResponse } from "../model/samplmodel";

class OrderService extends BaseService<
	any,
	any,
	any,
	any,
	any,
	
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "Orders";
	}

	getByFilter() {}
}

export default new OrderService();