import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './mainmenu.css';

function MainMenu() {
  return (
    <>
      {/* Bootstrap Navbar - Responsive Menü */}
      <Navbar bg="white" variant="light" expand="lg" className="mb-3">
        <Container className="justify-content-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" /> {/* Sağda hamburger menü */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto"> {/* Menü ortalanıyor */}
              <Nav.Link as={Link} to="/">Ana Sayfa</Nav.Link>
              <Nav.Link as={Link} to="/search">Tüm Ürünler</Nav.Link>
              <Nav.Link as={Link} to="/about">Hakkımızda</Nav.Link>
              <Nav.Link as={Link} to="/contact">İletişim</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MainMenu;
