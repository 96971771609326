
import {BaseService} from "../core/services/base-service";

class ProductService extends BaseService<
	any,
	any,
any,
	any,
	any,
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "Products";
	}

}

export default new ProductService();