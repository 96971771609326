import {BaseService} from "../core/services/base-service";

class ProductReviewService extends BaseService<
	any,
	any,
any,
	any,
	any,
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "ProductReviews";
	}

}

export default new ProductReviewService();