import React from 'react'
import Reviewform from '../reviewform'
import ProductDetail from './ProductDetail'
import ProductReviews from './ProductReviews'

type Props = {
    product:any;
}

function DescriptionReviewSection(props:Props) {

  return (
    <>
     <div className="description-review-area pb-100px" data-aos="fade-up" data-aos-delay="200">
                <div className="container">
                    <div className="description-review-wrapper">
                        <div className="description-review-topbar nav">
                            <a className="active" data-bs-toggle="tab" href="#des-details2">Ürün Detayları</a>
                            <a data-bs-toggle="tab" href="#des-details3">Yorumlar ({props.product?.productReview?.length})</a>
                        </div>
                        <div className="tab-content description-review-bottom">
                            <div id="des-details2" className="tab-pane active">
                                <div className="product-anotherinfo-wrapper">
                                    <ul>
                                        {
                                            props.product?.productDetail.map((r:any)=>(
                                                <li><span>{r.name}</span> {r.description}</li>
                                            ))
                                        }
                                        
                                    </ul>
                                </div>
                            </div>
                           <ProductDetail productDetail={props.product?.description}/>
                           <ProductReviews productReviews={props.product?.productReview}/>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default DescriptionReviewSection
