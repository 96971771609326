import React, { useState, useEffect } from 'react';
import AccountUserService from '../../services/account-user-service';
import  toastr from "toastr";
function AccountUpdate() {
  // LocalStorage'dan gelen verileri state'e yazıyoruz
  const [userData, setUserData] = useState({
    id:0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    district: '',
    neighbourhood: '',
    fullAddress: '',
    userId: '' // UserId'yi de tutacağız
  });

  useEffect(() => {
    const mailAddress = localStorage.getItem('mailAddress'||'mailaddress@mailaddress.com');
    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    
    // Eğer userData varsa state'i güncelle
    if (storedUserData) {
      setUserData({
        id:storedUserData.id,
        firstName: storedUserData.firstName || '',
        lastName: storedUserData.lastName || '',
        email: mailAddress || '',
        phoneNumber: storedUserData.phoneNumber || '',
        city: storedUserData.city || '',
        district: storedUserData.district || '',
        neighbourhood: storedUserData.neighbourhood || '',
        fullAddress: storedUserData.fullAddress || '',
        userId: storedUserData.userId || '' // UserId localStorage'dan alınır
      });
    }
  }, []);

  // Değişiklikleri yakalayan fonksiyon
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  };

  // Form submit edildiğinde güncelleme isteğini yap
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Sayfa yenilemeyi engelle

    const updateRequest = {
      id: userData.id, // Bu ID'yi uygun şekilde ayarlamalısın
      userId: userData.userId, // LocalStorage'dan alınan UserId
      phoneNumber: userData.phoneNumber,
      city: userData.city,
      district: userData.district,
      neighbourhood: userData.neighbourhood,
      fullAddress: userData.fullAddress,
      firstName:userData.firstName,
      lastName:userData.lastName
    };

    try {
      const response = await AccountUserService.update(updateRequest);
      console.log(updateRequest);      
      toastr.success('Profil başarıyla güncellendi.');
      const updatedUserData = {
        ...JSON.parse(localStorage.getItem('userData') || '{}'),
        phoneNumber: updateRequest.phoneNumber,
        city: updateRequest.city,
        district: updateRequest.district,
        neighbourhood: updateRequest.neighbourhood,
        fullAddress: updateRequest.fullAddress,
        firstName:updateRequest.firstName,
        lastName:updateRequest.lastName
      };
      localStorage.removeItem('userData');
      localStorage.setItem('userData', JSON.stringify(updateRequest));
    } catch (error) {
      
    }
  };

  return (
    <>
      <div className="myaccount-info-wrapper">
        <div className="account-info-wrapper">
          <h4>Profil Bilgilerim</h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="billing-info">
                <label>İsim</label>
                <input
                  type="text" required
                  name="firstName"
                  value={userData?.firstName}
                  onChange={handleChange}
                   // İsim alanı güncellenmiyor, sadece görüntülemek için
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="billing-info">
                <label>Soyisim</label>
                <input
                  type="text" required
                  name="lastName"
                  value={userData?.lastName}
                  onChange={handleChange}
                   // Soyisim alanı güncellenmiyor, sadece görüntülemek için
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="billing-info">
                <label>Email Adresi</label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  disabled // Email alanı güncellenmiyor, sadece görüntülemek için
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="billing-info">
                <label>Telefon Numarası</label>
                <input
                  type="text" required
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="billing-info">
                <label><b>Adres Bilgileri</b></label>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="billing-info">
                <label>Şehir</label>
                <input
                  type="text" required
                  name="city"
                  value={userData.city}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="billing-info">
                <label>İlçe</label>
                <input
                  type="text" required
                  name="district"
                  value={userData.district}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="billing-info">
                <label>Mahalle</label>
                <input
                  type="text" required
                  name="neighbourhood"
                  value={userData.neighbourhood}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="billing-info">
                <label>Açık Adres</label>
                <textarea
                  name="fullAddress"
                  required
                  value={userData.fullAddress}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="billing-back-btn">
            <div className="billing-back"></div>
            <div className="billing-btn">
              <button type="submit">Kaydet</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AccountUpdate;
