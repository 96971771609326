import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import BannerSection from './components/bannersection';
import Footer from './components/footer';
import HeroIntroSlider from './components/herointroslider';
import MainMenu from './components/mainmenu/mainmenu';
import Modal from './components/modal';
import { Navbar } from './components/navbar/navbar';
import ProductCategory from './components/productCategory';
import ProductTab from './components/productTab';
import RouteDefinitions from './components/routes/route-definitions';
import ErrorPage from './pages/404';
import About from './pages/About';
import Account from './pages/Account';
import Cart from './pages/Cart';
import Contact from './pages/Contact';
import LoginPage from './pages/LoginPage';
import Mpage from './pages/Mpage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Shop3column from './pages/shop-3column';
import SingleProduct from './pages/SingleProduct';
import { useEffect, useState } from 'react';
import toastr from 'toastr';
import companyService from './services/company-service';
import GoogleTranslate from './components/googleTranslate';


function App() {

  const [company,setCompany]=useState<any>();
  useEffect(() => {
    companyService.setCompany();
    setCompany(companyService.getCompany());
	  }, []);
  return (
    <div className="App">
      <Navbar mainLogo={company?.mainLogo} />

      <>

      {/* { <Mpage/> } */}
     {/* {<LoginPage/>  } */}
      {/* <Account/>  */}
     {/* {<PrivacyPolicy/> } */}
     {/* <Shop3column/> */}
     {/* <Cart/>   */}
    {/* <SingleProduct/> */}
     {/* <About/> */}
      {/* <Contact/> */}
      <RouteDefinitions company={company} />

      <Footer  company={company}/>
      <Modal/>
      </>
    </div>
  );
}

export default App;
