import React from 'react'
import { Link } from 'react-router-dom'


type Props = {
    mainLogo:string;
}

function NavbarBottom2(props: Props) {
    const userData = JSON.parse(localStorage.getItem('userData') || 'null');
    console.log(userData);
    
  return (
    <div className="header-bottom d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center">
                    <div className="col-auto align-self-center">
                        <div className="header-logo">
                            <Link to="/"><img src={props.mainLogo} alt="Site Logo" /></Link>
                        </div>
                    </div>

                    <div className="col align-self-center">
                        <div className="header-actions">
                            <div className="header_account_list">
                            <Link to="/search" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></Link>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input className="form-control" placeholder="Enter your search key" type="text"/>
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className="header-bottom-set dropdown">
                                <button className="dropdown-toggle header-action-btn" data-bs-toggle="dropdown"><i
                                        className="icon-user"></i></button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                {userData && (
                                        <li>
                                            <li><Link className="dropdown-item" to="account">Hesabım</Link></li>                                        
                                        </li>
                                    )}
                                {!userData && (
                                        <li>
                                            <Link className="dropdown-item" to="auth">Giriş Yap/Kayıt Ol</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <Link to="cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="icon-handbag"></i>
                            </Link>
                          
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default NavbarBottom2
