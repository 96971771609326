import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
type Props = {
    company:any;
}
function Footer(props:Props) {
  return (
    <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Sosyal Medyalarımız</h4>
                                
                                <ul className="link-follow">
                                    
                                    <li className="li">
                                        <a  title="Whatsapp" href={'https://wa.me/'+props.company?.phoneNumber} target="_blank"> <FontAwesomeIcon icon={faWhatsapp} size="2x" /></a>
                                    </li>
                                    <li className="li">
                                        <a  title="Instagram" href={props.company?.instagramLink} style={{marginLeft:'10px'}} target="_blank"> <FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-lg-4 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Bilgilendirme</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><Link className="single-link" to="about">Hakkımızda</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-6 mb-lm-30px" data-aos="fade-up" data-aos-delay="600">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Hesabım</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                            <li className="li"><Link className="single-link" to="account">
                                                Profilim</Link>
                                            </li>
                                            <li className="li"><Link className="single-link" to="cart">Sepetim</Link></li>
                                            <li className="li"><Link className="single-link" to="login">Giriş</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
 
        </div>
    </div>
  )
}

export default Footer
