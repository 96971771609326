import React, { useEffect } from 'react'
import GoogleTranslate from '../../googleTranslate';

function NavbarTop() {
    
  return (
    <>
      <div className="header-top section-fluid bg-black">
            <div className="container">
                <div className="row row-cols-lg-2 align-items-center">
                    <div className="col text-center text-lg-start">
                        <div className="header-top-massege">
                            <p>Hoşgeldiniz </p>
                        </div>
                    </div>
                    <div className="col d-none d-lg-block">
                        <div className="header-top-set-lan-curr d-flex justify-content-end">
                            <div className="header-top-curr dropdown">
                            {<GoogleTranslate/> }
                              
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default NavbarTop;
