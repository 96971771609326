import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/breadcrumb';
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import orderService from '../services/order-service';
import toastr from 'toastr';
import companyService from '../services/company-service';

function Cart() {
    const [company, setCompany] = useState<any>();
    const [cartItems, setCartItems] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [order, setOrder] = useState({
        FirstName: "",
        LastName: "",
        Address: "",
        PhoneNumber: "",
        OrderProduct: []
    });

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setOrder({
            ...order,
            [name]: value
        });
    };

    const transformCartItemsToOrderProducts = () => {
        return cartItems.map(item => ({
            productId: item.productId,
            colorId: item.colorId,
            sizeId: item.sizeId,
            quantity: item.quantity
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        
        const orderProducts = transformCartItemsToOrderProducts();
        const orderData = {
            ...order,
            OrderProduct: orderProducts
        };
        if (orderData.OrderProduct.length<=0) {
            console.log(order.OrderProduct);
            
            toastr.error('Henüz Ürününüz Yok');
            return;
        }

        await orderService.add(orderData).then((r) => {
            toastr.success(r.data);
            console.log(r);
        
            // WhatsApp yönlendirmesi
            const message = encodeURIComponent(`Siparişiniz başarıyla oluşturuldu. Sipariş numaranız: ${r.data.id} Teşekkür ederiz!`);
            console.log(company.whatsappLink);
            
            const whatsappURL = `https://wa.me/${company?.whatsappLink}?text=${message}`;
            window.open(whatsappURL, '_blank');
            localStorage.removeItem('cart');
            window.location.href='/';
        });
        handleCloseModal();
    };

    const handleQuantityChange = (index: number, newCount: number) => {
        const updatedCartItems = [...cartItems];
        if (newCount <= 0) {
            updatedCartItems.splice(index, 1);
        } else {
            updatedCartItems[index].quantity = newCount;
        }
        setCartItems(updatedCartItems);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    };

    const handleRemoveItem = (index: number) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems.splice(index, 1);
        setCartItems(updatedCartItems);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    };

    useEffect(() => {
        // localStorage'dan sepet verisini al
        const cartData = localStorage.getItem("cart");
        if (cartData) {
            setCartItems(JSON.parse(cartData));
        }
        companyService.setCompany();
        setCompany(companyService.getCompany());

        // localStorage'daki kullanıcı bilgilerini al ve formu doldur
        const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
        if (storedUserData ) {
            setOrder({
                FirstName: storedUserData.firstName || '',
                LastName: storedUserData.lastName || '',
                Address: `Tam Adres: ${storedUserData.fullAddress || ''}, Şehir: ${storedUserData.city || ''},İlçe: ${storedUserData.district || ''},Mahalle: ${storedUserData.neighbourhood || ''},`,
                PhoneNumber: storedUserData.phoneNumber || '',
                OrderProduct: []
            });
        }
    }, []);

    const handleClearCart = () => {
        localStorage.removeItem("cart");
        setCartItems([]);
    };

    const totalAmount = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);

    return (
        <>
            <BreadCrumb title={'Alışveriş'} firstItem={'Ana Sayfa'} secondItem={'Sepetim'} />
            <div className="cart-main-area pt-100px pb-100px">
                <div className="container">
                    <h3 className="cart-page-title">Sepetim</h3>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <form action="#" onSubmit={(e) => e.preventDefault()}> {/* Form gönderimini engelle */}
                                <div className="table-content table-responsive cart-table-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Resim</th>
                                                <th>Ürün Adı</th>
                                                <th>Renk</th>
                                                <th>Beden</th>
                                                <th>Ücret</th>
                                                <th>Adet</th>
                                                <th>Sil</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.length > 0 ? (
                                                cartItems.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="product-thumbnail">
                                                            <Link key={item?.productId} className='mycu' to={`/single-product/${item?.productId}`} ><img className="img-responsive ml-15px" src={item?.imageUrl ? item.imageUrl : 'assets/images/product-image/1.jpg'} alt="" /></Link>
                                                        </td>
                                                        <td className="product-name"><a href="#">{item?.name}</a></td>
                                                        <td className="product-price-cart">
                                                            <div className="pro-details-size-color d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                {item?.colorCode ? (
                                                                    <div className="pro-details-color-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <div
                                                                            className="blue"
                                                                            style={{
                                                                                backgroundColor: item?.colorCode,
                                                                                width: '20px',  // Kare boyutu
                                                                                height: '20px', // Kare boyutu
                                                                                display: 'inline-block',
                                                                                borderRadius: '3px', // Yuvarlak köşeler (isteğe bağlı)
                                                                                border: '1px solid #ddd', // Çevresine ince bir çerçeve
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ textAlign: 'center' }}>Seçilmedi</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="product-name">
                                                            <div>
                                                                {item?.sizeName ? item?.sizeName : 'Seçilmedi'}
                                                            </div>
                                                        </td>
                                                        <td className="product-price-cart"><span className="amount">{item?.price}₺</span></td>
                                                        <td className="product-quantity">
                                                            <div className="cart-plus-minus d-flex" style={{ alignItems: 'center' }}>
                                                                <button
                                                                    type="button"
                                                                    style={{
                                                                        border: 'none',
                                                                        backgroundColor: '#f0f0f0',
                                                                        padding: '5px 10px',
                                                                        cursor: 'pointer',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    onClick={() => handleQuantityChange(index, item.quantity - 1)}
                                                                >
                                                                    -
                                                                </button>
                                                                <input
                                                                    className="cart-plus-minus-box"
                                                                    type="number"
                                                                    name="qtybutton"
                                                                    value={item?.quantity}
                                                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        width: '40px',
                                                                        margin: '0 10px'
                                                                    }}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    style={{
                                                                        border: 'none',
                                                                        backgroundColor: '#f0f0f0',
                                                                        padding: '5px 10px',
                                                                        cursor: 'pointer',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    onClick={() => handleQuantityChange(index, item.quantity + 1)}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="product-remove">
                                                            <a onClick={() => handleRemoveItem(index)}><i className="icon-close"></i></a>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7}>Sepetiniz boş.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="table-footer-wrapper" style={{ textAlign: 'right', padding: '15px', borderTop: '2px solid #ddd', borderRight: '2px solid #ddd', borderBottom: '2px solid #ddd', borderLeft: '2px solid #ddd', borderRadius: '5px', marginTop: '20px' }}>
                                        <strong>Tutar:</strong> {totalAmount.toFixed(2)} ₺
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="cart-shiping-update-wrapper d-flex justify-content-between align-items-center">
                                            <div className="cart-shiping-update">
                                                <a href="#" onClick={handleClearCart}>Sepeti Temizle</a>
                                            </div>

                                            <div className="cart-clear">
                                                
                                                    <a href="#" onClick={handleShowModal}>
                                                    Alışverişi Bitir
                                                </a>
                                            
                                                

                                                <Modal show={showModal} onHide={handleCloseModal} size='xl'>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Sipariş Bilgileri</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form onSubmit={handleSubmit}>
                                                            <Form.Group controlId="formFirstName">
                                                                <Form.Label>Ad</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="FirstName"
                                                                    value={order.FirstName}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </Form.Group>

                                                            <Form.Group controlId="formLastName">
                                                                <Form.Label>Soyad</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="LastName"
                                                                    value={order.LastName}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </Form.Group>

                                                            <Form.Group controlId="formAddress">
                                                                <Form.Label>Adres</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    name="Address"
                                                                    value={order.Address}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </Form.Group>

                                                            <Form.Group controlId="formPhoneNumber">
                                                                <Form.Label>Telefon</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="PhoneNumber"
                                                                    value={order.PhoneNumber}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </Form.Group>

                                                            <Button
                                                                type="submit"
                                                                style={{
                                                                    backgroundColor: "#f39c12",
                                                                    color: "white",
                                                                    bottom: "20px",
                                                                    right: "20px",
                                                                    border: "none",
                                                                    borderRadius: "5px"
                                                                }}>
                                                                Siparişi Tamamla
                                                            </Button>
                                                        </Form>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cart;
