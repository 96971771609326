import {BaseService} from "../core/services/base-service";

class SizeService extends BaseService<
	any,
	any,
any,
	any,
	any,
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "Sizes";
	}

}

export default new SizeService();