import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

type Props = {
    product: any
    to?: string
}

function ProductCard(props: Props) {

    useEffect(() => {

    }, [])

    return (
        <>
            <div className="product mb-25px">
                <div className="thumb">
                    <Link key={props.product?.id} to={`/single-product/${props.product?.id}`} className="image">
                        <img src={props.product.productImage[0]?.imageUrl ? props.product.productImage[0]?.imageUrl : "assets/images/product-image/5.jpg"} alt="Product" />


                        <img
                            className="hover-image"
                            style={{ height: '250px !important', objectFit: 'cover' }}  // Yükseklik ve obje sığdırma CSS stilini buraya ekledik
                            src={props.product.productImage[0]?.imageUrl ? props.product.productImage[0]?.imageUrl : "assets/images/product-image/5.jpg"}
                            alt="Product"
                        />
                    </Link>
                    {/* <span className="badges">
                                            <span className="sale">-5%</span>
                                            <span className="new">New</span>
                                        </span> */}
                    <div className="actions">

                        <Link key={props.product?.id} to={`/single-product/${props.product?.id}`} className="action wishlist" title="Quick view" ><i className="icon-size-fullscreen"></i></Link>

                    </div>
                    <Link title="Add To Cart" className=" add-to-cart" key={props.product?.id} to={`/single-product/${props.product?.id}`}>
                        Sepete Ekle
                    </Link>
                </div>
                <div className="content">
                    <h5 className="title"><a href="shop-left-sidebar.html">{props.product.name}</a>
                        {

                        }
                        <small>{ }</small>
                    </h5>

                    <span className="price">
                        <span className="new">{props.product.price}₺</span>
                        {/* <span className="old">$40.50</span> */}
                    </span>
                </div>
            </div>
        </>
    )
}

export default ProductCard
