import React, { useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import productReviewService from '../services/product-review-service';
import toastr from 'toastr';

function Reviewform() {
    let { productId } = useParams();
    const linkRef = useRef<HTMLAnchorElement>(null);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [point, setRating] = useState(5);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const productIdNum = productId ? parseInt(productId, 10) : 1;
        // Tüm bilgileri toplamak
        const formData = {
            name,
            email,
            message,
            point,
            productId: productIdNum
        };

        await productReviewService.add(formData).then(() => {
            toastr.success('Mesajınız başarıyla gönderildi');
            navigate(`/single-product/${productId}`, { replace: true });
            navigate('/temporary-route', { replace: true });
             setTimeout(() => navigate(`/single-product/${productId}`, { replace: true }), 0);
           
        }).catch((r) => console.log(r))

    };

    return (
        <>
            <div className="ratting-form-wrapper pl-50">
                <h3>Yorum Yaz</h3>
                <div className="ratting-form">
                    <form onSubmit={handleSubmit}>
                        <div className="star-box">
                            <span>Puan Seçiniz:</span>
                            <div className="rating-product">
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <i
                                        key={star}
                                        className={star <= point ? "ion-android-star" : "ion-android-star-outline"}
                                        onClick={() => setRating(star)}
                                        style={{ cursor: 'pointer' }}
                                    ></i>
                                ))}
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-md-6">
                                <div className="rating-form-style">
                                    <input
                                        placeholder="İsim"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="rating-form-style">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="rating-form-style form-submit">
                                    <textarea
                                        name="Your Review"
                                        placeholder="Mesajınız"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                    <button
                                        className="btn btn-primary btn-hover-color-primary"
                                        type="submit"
                                        value="Submit"
                                    >
                                        Gönder
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Reviewform
