import React, { useEffect } from 'react';

function GoogleTranslate() {
   useEffect(() => {
        const addGoogleTranslateScript = document.createElement('script');
        addGoogleTranslateScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        document.body.appendChild(addGoogleTranslateScript);
    
        (window as any).googleTranslateElementInit = () => {
          new (window as any).google.translate.TranslateElement(
            { pageLanguage: 'en' }, // Varsayılan dili ayarlayın
            'google_translate_element'
          );
        };
      }, []);

  return (
    <div id="google_translate_element"></div>
  );
}

export default GoogleTranslate;