import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
    return (
        <>
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row breadcrumb_box  align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
                                    <h2 className="breadcrumb-title">404</h2>
                                </div>
                                <div className="col-lg-6  col-md-6 col-sm-12">
                                    <ul className="breadcrumb-list text-center text-md-end">
                                        <li className="breadcrumb-item"><a href="index.html">Ana Sayfa</a></li>
                                        <li className="breadcrumb-item active">404</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blank-page-area pb-100px pt-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blank-content-header">
                                <h1>Yolunuzu Mu Kaybbetiniz.</h1>
                            </div>
                            <div className="page-not-found text-center">
                                <Link to={'/'}>Dönün</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage
