import {AxiosResponse} from "axios";
import axiosInstance from "../interceptors/axios-interceptor";
import { BASE_API_URL } from "../../environment/environment";

export class BaseService<
	GetAllType,
	GetListType,
	GetByIdType,
	AddRequestType,
	AddResponseType,
	UpdateRequestType,
	UpdateResponseType,
> {
	public apiUrl: string;

	constructor() {
		this.apiUrl = "";
	}

	getAll(): Promise<AxiosResponse<GetAllType, any>> {
		return axiosInstance.get<GetAllType>(this.apiUrl);
	}
	getList(pageIndex:number,pageSize:number): Promise<AxiosResponse<GetAllType, any>> {
		console.log(BASE_API_URL+this.apiUrl+'?PageIndex='+pageIndex+'&PageSize='+pageSize);		
		axiosInstance.get<GetAllType>(this.apiUrl+'?PageIndex='+pageIndex+'&PageSize='+pageSize).then((r)=>{
			console.log(r);
			
		}).catch((r)=>{
			console.log('CATCH '+ r);
			
		})
		return axiosInstance.get<GetAllType>(this.apiUrl+'?PageIndex='+pageIndex+'&PageSize='+pageSize);
	}


	getById(id: any): Promise<AxiosResponse<GetByIdType, any>> {
		return axiosInstance.get<GetByIdType>(this.apiUrl + "/" + id);
	}

	add(request: AddRequestType): Promise<AxiosResponse<AddResponseType, any>> {
		console.log(request);
		
		return axiosInstance.post<AddResponseType>(this.apiUrl, request);
	}

	update(
		request: UpdateRequestType,
	): Promise<AxiosResponse<UpdateResponseType, any>> {
		return axiosInstance.put<UpdateResponseType>(this.apiUrl, request);
	}

	delete(id: number) {
		return axiosInstance.delete(this.apiUrl + "/" + id);
	}
}