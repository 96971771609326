import React from 'react'
import NavbarTop from './navbartop/navbartop';
import NavbarBottom from './navbarbottom';
import NavbarBottom2 from './navbarbottom2';
import MainMenu from '../mainmenu/mainmenu';

type Props = {
    mainLogo:string;
}

export const Navbar=(props: Props)=> {

    return (
        <>
            <NavbarTop/>
            <NavbarBottom mainLogo={props.mainLogo} />
            <NavbarBottom2 mainLogo={props.mainLogo}/>
            <MainMenu/>
            
        </>
    );
}

