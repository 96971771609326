import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import userService from '../../services/user-service';
import toastr from 'toastr';
function Register() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        userEmail: '',
        userPassword: ''
    });

    const [errorMessage, setErrorMessage] = useState('');

    // Form verilerini güncelleme
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Formu gönderme ve kontroller
    const handleSubmit = async () => {
        const { firstName, lastName, userEmail, userPassword } = formData;

        // Boş alan kontrolü
        if (!firstName || !lastName || !userEmail || !userPassword) {
            setErrorMessage('Lütfen tüm alanları doldurunuz.');
            return;
        }

        // Email formatı kontrolü
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userEmail)) {
            setErrorMessage('Geçerli bir email adresi giriniz.');
            return;
        }

        try {
            // Kullanıcı kaydı için verileri hazırlıyoruz
            const createUserRequest = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.userEmail,
                password: formData.userPassword
            };

            // userService.add ile API'ye gönderiyoruz
            await userService.add(createUserRequest).then(()=>{
                toastr.success('Kullanıcı başarıyla kaydedildi!');
                setFormData({
                    firstName: '',
                    lastName: '',
                    userEmail: '',
                    userPassword: ''
                }); 
            }).catch((r)=>{toastr.error('Bir Sorun İle Karşılaşıldı')});

            // Başarılı kayıt mesajı ya da yönlendirme
            
           // Formu temizle

        } catch (error) {
            console.error('Kullanıcı kaydedilemedi:', error);
            setErrorMessage('Kullanıcı kaydı sırasında bir hata oluştu.');
        }
    };

    return (
        <Container>
            <Form>
                <Row>
                    {/* İsim ve Soyisim Yan Yana */}
                    <Col md={6}>
                        <Form.Group controlId="firstName">
                            <Form.Label>İsim</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                placeholder="İsim"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="lastName">
                            <Form.Label>Soyisim</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                placeholder="Soyisim"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* Email */}
                <Form.Group controlId="email" className="mt-3">
                    <Form.Label>Email Adresi</Form.Label>
                    <Form.Control
                        type="email"
                        name="userEmail"
                        placeholder="Email Adresi"
                        value={formData.userEmail}
                        onChange={handleChange}
                    />
                </Form.Group>

                {/* Şifre (En altta) */}
                <Form.Group controlId="password" className="mt-3">
                    <Form.Label>Şifre</Form.Label>
                    <Form.Control
                        type="password"
                        name="userPassword"
                        placeholder="Şifre"
                        value={formData.userPassword}
                        onChange={handleChange}
                    />
                </Form.Group>

                {/* Hata Mesajı */}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                {/* Kayıt Ol Butonu */}
                <div className="button-box mt-4">
                    <Button variant="primary" type="button" onClick={handleSubmit}>
                        Kayıt Ol
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default Register;
