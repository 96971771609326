import axios from "axios";
import axiosInstance from "../core/interceptors/axios-interceptor";
import {BaseService} from "../core/services/base-service";
import { GetAllSampleResponse, GetSampleDetailResponse, AddSampleRequest, AddSampleResponse, UpdateSampleRequest, UpdateSampleResponse } from "../model/samplmodel";

class AccountUserService extends BaseService<
	any,
	any,
	any,
	any,
	any,
	
	any,
	any
> {
	constructor() {
		super();
		this.apiUrl = "AccountUsers";
	}

	async getUserData(email: string, password: string) {
		try {
		  const response = await axios.get(`http://localhost:60805/api/AccountUsers/gett`, {
			params: {
			  Email: email,
			  Password: password
			}
		  });
		  return response.data; // API'den dönen veriyi dönüyoruz
		} catch (error) {
		  console.error('Error fetching user data:', error);
		  throw error;
		}
	  }
	
}

export default new AccountUserService();