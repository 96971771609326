import React, { useState } from 'react';
import accountUserService from '../../services/account-user-service';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [userData, setUserData] = useState(null); // Kullanıcı verisini tutacak state
    const navigate = useNavigate(); // React Router'dan navigate hook'u kullanıyoruz

    const handleLogin = async () => {
        if (!email || !password) {
            setErrorMessage('Lütfen tüm alanları doldurun.');
            return;
        }

        try {
            const data = await accountUserService.getUserData(email, password);
            setUserData(data);
            localStorage.setItem('userData', JSON.stringify(data));
            localStorage.setItem('password', password);
            localStorage.setItem('mailAddress',email);
            // Başarılı giriş mesajı toastr ile
            toastr.success('Giriş Başarılı, Ana sayfaya yönlendiriliyorsunuz...');

            // 2 saniye bekledikten sonra ana sayfaya yönlendirme
            setTimeout(() => {
                navigate('/'); // Ana sayfaya yönlendir
            }, 2000); // 2 saniye (2000 ms) bekletme

        } catch (error) {
            toastr.error('Geçersiz Kullanıcı Adı veya Şifre');
            setErrorMessage('Geçersiz kullanıcı adı veya şifre.');
        }
    };

    return (
        <>
            <form method="post">
                <input 
                    type="text" 
                    name="user-name" 
                    placeholder="Email Adresi" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <input 
                    type="password" 
                    name="user-password" 
                    placeholder="Şifre" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                />

                <div className="button-box">
                    <div className="login-toggle-btn">
                        <input type="checkbox" />
                        <a className="flote-none" href="javascript:void(0)">Beni Hatırla</a>
                        <a href="#">Şifremi Unuttum</a>
                    </div>

                    {/* Giriş Yap Butonu */}
                    <button type="button" onClick={handleLogin}>
                        <span>Giriş Yap</span>
                    </button>

                    {/* Hata Mesajı */}
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </div>
            </form>
        </>
    );
}

export default Login;
