import React, { useEffect, useState } from 'react'
import Reviewform from '../components/reviewform'
import BreadCrumb from '../components/breadcrumb'
import DescriptionReviewSection from '../components/SingleProductComponents/DescriptionReviewSection'
import { useParams } from 'react-router-dom';
import productService from '../services/product-service';
import toastr from "toastr";
function SingleProduct() {
    const { productId } = useParams();
    const [product, setProduct] = useState<any>();
    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === product.productImage.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? product.productImage.length - 1 : prevIndex - 1
        );
    };
    const [productColor, setProductColor] = useState<any>(null);
    const [productSize, setProductSize] = useState<any>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedColorId, setSelectedColorId] = useState(null);

    const handleColorClick = (colorId: any) => {
        setSelectedColorId(colorId);
        setProductColor(colorId);
    };
    const handleAddToCart = () => {
        // Renk seçimi kontrolü
        if (product?.productColor.length > 0) {
            if (productColor === null) {
                toastr.error('Lütfen Renk Seçiniz');
                return; // Renk seçimi yapılmadıysa işlemi durdur
            }
        }
    
        // Beden seçimi kontrolü
        if (product?.productSize.length > 0) {
            if (productSize === null) {
                toastr.error('Lütfen Beden Seçiniz');
                return; // Beden seçimi yapılmadıysa işlemi durdur
            }
        }
    
        // Seçilen renk ID'sine göre renk kodunu bul
        const selectedColor = product?.productColor.find((color: any) => color.colorId === productColor);
        const colorCode = selectedColor?.color?.code;
    
        // Seçilen beden ID'sine göre beden adını bul
        const selectedSize = product?.productSize.find((size: any) => size.sizeId === Number(productSize));
        const sizeName = selectedSize?.size?.name;
    
        // localStorage'dan mevcut cart'ı al
        const cartData = localStorage.getItem("cart");
        let cart: any[] = cartData ? JSON.parse(cartData) : [];
    
        // Sepete eklenecek ürün objesini oluştur
        const itemToAdd = {
            productId: product.id,
            name: product.name,
            price: product.price,
            imageUrl: product.productImage[currentIndex]?.imageUrl,
            colorId: productColor, // Seçilen rengin ID'si
            colorCode: colorCode, // Seçilen rengin kodu
            sizeId: productSize, // Seçilen bedenin ID'si
            sizeName: sizeName, // Seçilen bedenin adı
            quantity: 1, // Ürün miktarı, isteğe göre artırılabilir
        };
    
        // Aynı ürün zaten varsa miktarını artır, yoksa sepete yeni ürün ekle
        const existingItemIndex = cart.findIndex((item: any) =>
            item.productId === itemToAdd.productId &&
            item.colorId === itemToAdd.colorId &&
            item.sizeId === itemToAdd.sizeId
        );
    
        if (existingItemIndex !== -1) {
            // Aynı üründen varsa, quantity'yi artır
            cart[existingItemIndex].quantity += 1;
        } else {
            // Aynı üründen yoksa sepete yeni ürünü ekle
            cart.push(itemToAdd);
        }
    
        // localStorage'a güncel cart'ı kaydet
        localStorage.setItem("cart", JSON.stringify(cart));
        toastr.success('Ürün Sepete Eklendi');
    };
    



    const getProduct = async () => {
        if (productId !== undefined) {
            await productService.getById(parseInt(productId, 10)).then((r) => {
                setProduct(r.data);
                console.log(r.data);

            })
        }

    }
    useEffect(() => {
        getProduct();
    }, [])
    return (
        <>
            <BreadCrumb title={'Mağaza'} firstItem={'Ana Sayfa'} secondItem={'Mağaza'} thirdItem={'Ürün Detayı'} />


            <div className="product-details-area pt-100px pb-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">

                            <div className="swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-15px mb-15px">
                                <div className="swiper-wrapper">
                                    {/* Sadece aktif öğeyi göster */}
                                    {product?.productImage.length > 0 && (
                                        <div className="swiper-slide active">
                                            <img className="img-responsive m-auto h-250" src={product.productImage[currentIndex].imageUrl} alt="" />
                                        </div>
                                    )}
                                    <div className="swiper-buttons">
                                        <div className="swiper-button-prev" onClick={() => handlePrev}></div>
                                        <div className="swiper-button-next" onClick={() => { handleNext(); console.log(currentIndex); }
                                        }></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                            <div className="product-details-content quickview-content">
                                <h2>{product?.name}</h2>
                                {product?.productCategory.map(((r: any) => <small style={{ fontSize: '12px' }}>{r.category?.name}/</small>))}
                                <div className="pro-details-rating-wrap">
                                    <div className="rating-product">
                                        {
                                            product && product.productReview.length > 0 ? (
                                                (() => {
                                                    // Puanları topla ve ortalamayı hesapla
                                                    const totalPoints = product.productReview.reduce((sum: any, review: any) => sum + review.point, 0);
                                                    const averageRating = totalPoints / product.productReview.length;
                                                    const roundedRating = Math.round(averageRating);

                                                    // Yıldızları oluşturma
                                                    return Array.from({ length: 5 }, (_, i) => (
                                                        <i
                                                            key={i}
                                                            className={i < roundedRating ? "ion-android-star" : "ion-android-star-outline"}>
                                                        </i>
                                                    ));
                                                })()
                                            ) : (
                                                // Eğer değerlendirme yoksa, boş yıldızları göster
                                                Array.from({ length: 5 }, (_, i) => (
                                                    <i key={i} className="ion-android-star-outline"></i>
                                                ))
                                            )
                                        }
                                    </div>
                                    <span className="read-review"><a className="reviews" href="#">Değerlendirme ({product?.productReview.length})</a></span>
                                </div>
                                <div className="pricing-meta">
                                    <ul>
                                        <li className="old-price not-cut">{product?.price}₺</li>
                                    </ul>
                                </div>
                                <p className="quickview-para">{product?.description}</p>
                                <div className="product-details-table table-responsive pro-details-quality">

                                </div>

                                <div className="pro-details-size-color d-flex">
                                    {
                                        product?.productColor.length > 0 && (
                                            <div className="pro-details-color-wrap">
                                                <span>Renk</span>
                                                <div className="pro-details-color-content">
                                                    <ul>
                                                        {
                                                            product?.productColor?.map((r: any) => (
                                                                <li
                                                                    key={r.colorId}
                                                                    className="blue"
                                                                    onClick={() => handleColorClick(r.colorId)}
                                                                    style={{
                                                                        backgroundColor: r.color?.code,
                                                                        border: selectedColorId === r.colorId ? '2px solid #434A54' : 'none',
                                                                    }}
                                                                ></li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        product?.productSize.length > 0 && (
                                            <div className="product-size">
                                                <span>Beden</span>
                                                <select onChange={(e) => setProductSize(e.target.value)}>
                                                    <option value="" disabled selected>Beden Seçiniz</option>
                                                    {
                                                        product?.productSize?.map((r: any) => (
                                                            <option key={r.sizeId} value={r.sizeId}>
                                                                {r.size?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        )
                                    }

                                </div>



                                <div className="pro-details-quality">

                                    <div className="pro-details-cart">
                                        <button onClick={handleAddToCart} className="add-cart btn btn-primary btn-hover-primary ml-4"     >
                                            Sepete Ekle
                                        </button>
                                    </div>
                                </div>

                                <div className="pro-details-social-info">
                                    <span>Paylaş</span>
                                    <div className="social-info">
                                        <ul className="d-flex">
                                            <li>
                                                <a href="#"><i className="ion-social-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-google"></i></a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="ion-social-instagram"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <DescriptionReviewSection product={product} />


        </>
    )
}

export default SingleProduct
