import React from 'react'
import { Link } from 'react-router-dom'

type Props ={
    mainImage:string;
}

function HeroIntroSlider(props:Props) {
  return (
    <div className="section ">
        <div className="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1">
            <div className="swiper-wrapper">
                <div className="hero-slide-item slider-height swiper-slide d-flex">
                    <div className="container align-self-center">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-7 align-self-center">
                                <div className="">
                                    <span className="category">Title</span>
                                    <h2 className="title-1">Başlık </h2>
                                    <p>Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna</p>
                                    <Link to="search" className="btn btn-lg btn-primary btn-hover-dark mt-5">Ürünlerimiz</Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-5">
                                <div className="hero-slide-image">
                                    <img src={props?.mainImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
          
        </div>
    </div>
  )
}

export default HeroIntroSlider
